import React from 'react';

/**
 * User page
 * @returns {JSX.Element} User page content
 */
function User() {
    return (
        <div>
            <h1>Profil</h1>
        </div>
    );
}

export default User

