import React from 'react';

/**
 * Settings page
 * @returns {JSX.Element} Settings page content
 */
function Settings() {
  return (
    <div>
      <h1>Réglage</h1>
    </div>
  )
}
  
export default Settings
  