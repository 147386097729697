import React from 'react';

/**
 * Community page
 * @returns {JSX.Element} Community page content
 */
function Community() {
  return (
    <div>
      <h1>Communauté</h1>
    </div>
  )
}

  
export default Community
  