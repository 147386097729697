import React from 'react';

/**
 * NoMatch page
 * @returns {JSX.Element} NoMatch page content
 */
function NoMatch() {
  return (
    <main>
      <h1>404</h1>
    </main>
  )
}

export default NoMatch
  